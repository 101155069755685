import React from 'react'
import APP_VERSION from '../../../../model/appVersion'
const reactComponentLink = `https://package.autocomplete.logistics.cimpress.io/js/${APP_VERSION}/cimAddressAutocomplete.js`

export default function QuickStart() {
  return (
    <>
      <div className="row">
        <div className="col-md-12 card">
          <pre>
            <code className="language-html card-body">
              {`<!-- put following script tag into your page -->
  <script src="${reactComponentLink}"></script>`}
            </code>
          </pre>
        </div>
      </div>

      <div className="row mt-4 mb-3">
        <div className="col-md-12 card">
          <pre>
            <code className="language-html card-body">
              {`<!-- Use id, class or any other selector to identify the form where you want to use autocomplete -->
  <form class="some-form-selector">

      <!-- Use data-address-role to bind address fields. -->
      <div data-address-role="country">
          <label>Country</label>
          <select>
              <!-- Use any countries you need. We'll turn on autocomplete for those
              that are supported. -->
              <option value="NL">Netherlands</option>
              <!-- Use data-error-for-fields to display validation errors -->
              <span data-error-for-fields="country"></span>
          </select>
      </div>

      <!-- In some countries, it is a habit to combine street
      and house number into one field
      (Don't worry, you will get it as separate values though) -->
      <div data-address-role="streetNameAndHouseNumber">
          <label>Street</label>
          <input>
          <span data-error-for-fields="streetName houseNumber"></span>
      </div>

      <!-- In some countries, it is a habit to have street and house number as separate fields.
      You can specify both (combined and separate) and we will show only the one appropriate for the country,
      and hide the other -->
      <div data-address-role="streetNameAndHouseNumberGroup">
          <div data-address-role="streetName">
              <label>Street</label>
              <input>
              <span data-error-for-fields="streetName"></span>

          </div>
          <div data-address-role="houseNumber">
              <label className="required">House number</label>
              <input>
              <span data-error-for-fields="houseNumber"></span>
          </div>
      </div>
      <div data-address-role="city">
          <label>City</label>
          <input>
          <span data-error-for-fields="city"></span>

      </div>
      <div data-address-role="state">
          <label>State</label>
          <input>
          <span data-error-for-fields="state"></span>

      </div>
      <div data-address-role="postalCode">
          <label>Postal code</label>
          <input>
          <span data-error-for-fields="postalCode"></span>
      </div>

      <input type="button" onclick="onSubmit()" value="submit">
  </form>


  <script>
    // Initialize the autocomplete. We have included the package in the <head> section of the page.
    window.myForm = window.cimpressAddressAutocomplete.mountAutocomplete(
      document.querySelector('.some-form-selector'), {
        initialAddress: {
          city: '',
          state: '',
          streetAndHouseNumber: '',
          streetName: '',
          houseNumber: '',
          postalCode: '',
        }
      }
    )
    onSubmit = () => {
      window.myForm.validateForm().then(validationStatus => {
        // resolve validation status - maybe you want to prevent the user from sending the form,
        // you can display some warning etc.
      }).then(()=> {
        // process the form values
      }).catch(e => {
        if (e === window.cimpressAddressAutocomplete.validateForm.CANCELED) {
          // The promise will get rejected in case the user
          // hits 'submit' again while validating.
        }
      })
    }
  </script>`}
            </code>
          </pre>
        </div>
      </div>
    </>
  )
}
