import isoCountryCodes from '../isoCountryCodes'
import { deepTraverseChildren, findFirstAndApply } from './traversal'
import * as fieldNames from './fieldNames'
import KNOWN_ERROR_FIELDS from './knownErrorFields'

const KNOWN_FIELD_NAMES = Object.values(fieldNames).reduce(
  (result, fieldName) => {
    result[fieldName] = true
    return result
  },
  {}
)
const KNOWN_ERROR_ROLES = ['content', 'placeHolder']

function doesCountryCodeExistInSelect(countryCode, select) {
  for (let option of select.querySelectorAll('option')) {
    if (option.value === countryCode) {
      return true
    }
  }

  return false
}

function validateCountryCodes(select) {
  for (let option of select.querySelectorAll('option')) {
    const countryCode = option.value
    if (!isoCountryCodes.includes(countryCode)) {
      throw new Error(
        `Country code ${countryCode} is not valid ISO 3166-1 code.`
      )
    }
  }
}

function findDefaultCountryCode(child, supportedCountries) {
  let defaultCountryCode = ''
  findFirstAndApply(child, 'select', (select) => {
    defaultCountryCode = select.value
    if (!doesCountryCodeExistInSelect(defaultCountryCode, select)) {
      const firstCountryCode =
        select.children.length > 0 ? select.children[0].value : null

      if (firstCountryCode) {
        defaultCountryCode = firstCountryCode
      } else {
        defaultCountryCode = Object.values(supportedCountries)[0].countryCode
      }
    }

    validateCountryCodes(select)
  })

  return defaultCountryCode
}

function validateDomAnnotations(element) {
  const addressRole = element.getAttribute('data-address-role')
  const errorFields = element.getAttribute('data-error-for-fields')
  const errorRole = element.getAttribute('data-error-role')

  if (addressRole) {
    if (!KNOWN_FIELD_NAMES[addressRole]) {
      throw new Error(
        'Could not bind form, unknown address role: ' + addressRole
      )
    }
  }

  if (errorFields) {
    errorFields.split(' ').forEach((errorField) => {
      if (!KNOWN_ERROR_FIELDS.includes(errorField)) {
        throw new Error(
          'Could not bind form, unknown error field: ' + errorField
        )
      }
    })
  }

  if (errorRole) {
    if (!KNOWN_ERROR_ROLES.includes(errorRole)) {
      throw new Error('Could not bind form, unknown error field: ' + errorRole)
    }
  }

  return {
    containsErrorAnnotations: Boolean(errorFields) || Boolean(errorRole),
  }
}

function collectFormMetadata(element, supportedCountries) {
  let metadata = {
    streetAndHouse: {
      unifiedFieldFound: false,
      groupFound: false,
      streetFound: false,
      houseNumberFound: false,
    },
    defaultCountryCode: undefined,
    shouldUpdateErrorVisuals: false,
  }

  deepTraverseChildren(element, (child) => {
    const { containsErrorAnnotations } = validateDomAnnotations(child)
    metadata.shouldUpdateErrorVisuals =
      metadata.shouldUpdateErrorVisuals || containsErrorAnnotations

    const fieldAddressRole = child.getAttribute('data-address-role')
    if (fieldAddressRole) {
      if (fieldAddressRole === fieldNames.STREET_AND_HOUSE_UNIFIED) {
        metadata.streetAndHouse.unifiedFieldFound = true
      } else if (fieldAddressRole === fieldNames.STREET_AND_HOUSE_GROUP) {
        metadata.streetAndHouse.groupFound = true
      } else if (fieldAddressRole === fieldNames.STREET_NAME) {
        metadata.streetAndHouse.streetFound = true
      } else if (fieldAddressRole === fieldNames.HOUSE_NUMBER) {
        metadata.streetAndHouse.houseNumberFound = true
      } else if (fieldAddressRole === fieldNames.COUNTRY) {
        metadata.defaultCountryCode = findDefaultCountryCode(
          child,
          supportedCountries
        )
      }
    }
  })

  validateFormMetadata(metadata)
  return metadata
}

function validateFormMetadata(formMetadata) {
  const {
    unifiedFieldFound,
    groupFound,
    streetFound,
    houseNumberFound,
  } = formMetadata.streetAndHouse
  if (unifiedFieldFound && (streetFound || houseNumberFound) && !groupFound) {
    throw new Error(
      'Address autocomplete: if your form contains both "streetNameAndHouseNumber" and split fields "streetName" and "houseNumber", also mark the "streetNameAndHouseNumberGroup" to enable smart switching.'
    )
  }

  if (streetFound && !houseNumberFound) {
    throw new Error(
      'Address autocomplete: You need to specify both "streetName" and "houseNumber" or neither. You only seem to have the "streetName" in your form.'
    )
  }

  if (!streetFound && houseNumberFound) {
    throw new Error(
      'Address autocomplete: You need to specify both "streetName" and "houseNumber" or neither. You only seem to have the "houseNumber" in your form.'
    )
  }
}

export default collectFormMetadata
