import * as selectors from './selectors'

import {
  ADDRESS_UNVERIFIED,
  ADDRESS_VERIFIED,
  ADDRESS_INCOMPLETE,
  ADDRESS_FIELD_INVALID,
} from '../../model/address'

import * as googlePlaces from '../../model/googlePlacesApi'
import {
  singleConcurrentRequest,
  PROMISE_CANCELED,
} from '../../model/promiseTools'

export const SET_GOOGLE_PLACE = 'SET_GOOGLE_PLACE'
export const SET_GOOGLE_PLACE_IF_COMPLETE = 'SET_GOOGLE_PLACE_IF_COMPLETE'
export const SET_MANUAL_STREET_AND_HOUSE_NUMBER =
  'SET_MANUAL_STREET_AND_HOUSE_NUMBER'
export const CHANGE_COUNTRY_CODE = 'CHANGE_COUNTRY_CODE'
export const CHANGE_HOUSE_NUMBER = 'CHANGE_HOUSE_NUMBER'
export const CHANGE_STREET = 'CHANGE_STREET'
export const CHANGE_STREET_AND_HOUSE_NUMBER = 'CHANGE_STREET_AND_HOUSE_NUMBER'
export const CHANGE_CITY = 'CHANGE_CITY'
export const CHANGE_STATE = 'CHANGE_STATE'
export const CHANGE_POSTAL_CODE = 'CHANGE_POSTAL_CODE'
export const VALIDATE_FIELDS = 'VALIDATE_FIELDS'
export const VALIDATE_FORM = 'VALIDATE_FORM'
export const VERIFY_ADDRESS = 'VERIFY_ADDRESS'
export const FINISH_ADDRESS_VERIFICATION = 'FINISH_ADDRESS_VERIFICATION'
export const CANCEL_ADDRESS_VERIFICATION = 'CANCEL_ADDRESS_VERIFICATION'
export const SET_FORM_METADATA = 'SET_FORM_METADATA'

export const validateForm = () => ({
  type: VALIDATE_FORM,
})

export const setGooglePlace = (place) => ({
  type: SET_GOOGLE_PLACE,
  place,
})

export const setGooglePlaceIfComplete = (place) => ({
  type: SET_GOOGLE_PLACE_IF_COMPLETE,
  place,
})

export const setManualStreetAndHouseNumber = () => ({
  type: SET_MANUAL_STREET_AND_HOUSE_NUMBER,
})

export const changeCountryCode = (countryCode) => ({
  type: CHANGE_COUNTRY_CODE,
  countryCode,
})

export const changeStreet = (value) => ({
  type: CHANGE_STREET,
  value,
})

export const changeStreetAndHouseNumber = (value) => ({
  type: CHANGE_STREET_AND_HOUSE_NUMBER,
  value,
})

export const changeHouseNumber = (houseNumber) => ({
  type: CHANGE_HOUSE_NUMBER,
  houseNumber,
})

export const changeCity = (city) => ({
  type: CHANGE_CITY,
  city,
})

export const changeState = (state) => ({
  type: CHANGE_STATE,
  state,
})

export const changePostalCode = (postalCode) => ({
  type: CHANGE_POSTAL_CODE,
  postalCode,
})

export const validateFields = (...fieldNames) => ({
  type: VALIDATE_FIELDS,
  fieldNames,
})

const getPlace = singleConcurrentRequest(googlePlaces.getPlace)
const getPlaceId = googlePlaces.getPlaceId

function formatAddressAsStringForVerification(address) {
  return `${address.streetAndHouseNumber}, ${address.city}`
}

function isFormIncompleteButHouseNumberExists(state) {
  return (
    selectors.getFormStatus(state) === ADDRESS_INCOMPLETE &&
    selectors.getFieldStatuses(state).houseNumber !== ADDRESS_FIELD_INVALID
  )
}

export const verifyAddress = () => async (dispatch, getState) => {
  googlePlaces.getPlacePredictions.cancelPending()
  getPlace.cancelPending()

  const state = getState()

  if (
    selectors.getFormStatus(state) === ADDRESS_UNVERIFIED ||
    isFormIncompleteButHouseNumberExists(state)
  ) {
    dispatch({ type: VERIFY_ADDRESS })

    const address = selectors.getAddress(state)

    try {
      const predictions = await googlePlaces.getPlacePredictions(
        formatAddressAsStringForVerification(address),
        address.countryCode
      )

      // We cannot be sure it's the right place in case of more than 1 result.
      if (predictions.length === 1) {
        const place = await getPlace(getPlaceId(predictions[0]))
        return dispatch(setGooglePlaceIfComplete(place))
      }
    } catch (e) {
      if (e === PROMISE_CANCELED) {
        throw e
      } else {
        console.error(e)
      }
    }

    return dispatch(cancelAddressVerification())
  }
}

export const restartAddressVerificationIfPending = () => async (
  dispatch,
  getState
) => {
  if (selectors.getIsVerificationPending(getState())) {
    dispatch(validateForm())
    // If the address has been just changed to the last known verified address
    // we can terminate right here and not ask google again.
    if (selectors.getFormStatus(getState()) === ADDRESS_VERIFIED) {
      googlePlaces.getPlacePredictions.cancelPending()
      getPlace.cancelPending()
      return dispatch(cancelAddressVerification())
    } else {
      return dispatch(verifyAddress())
    }
  }
}

export const cancelAddressVerification = () => ({
  type: CANCEL_ADDRESS_VERIFICATION,
})

export const setFormMetadata = (metadata, initialAddress) => ({
  type: SET_FORM_METADATA,
  metadata,
  initialAddress,
})
