import { STREET_FORMAT } from '../address'
import { convertGooglePlaceToAddress, addressContainsState } from './eu'
import { streetFunctions } from '../leftSideHouseNumber'

/**
 * Validate postal code
 * Postal codes of the form: 'DDDDD'. All digits are used. First two digits indicate the department, and range from 01 to 98, or 00 for army.
 *
 * @link https://rgxdb.com/r/354H8M0X
 * @param {string} value
 */
function validatePostalCode(value) {
  return value.trim().match(/^(?:[0-8]\d|9[0-8])\d{3}$/) !== null
}

export const FR = {
  countryCode: 'FR',
  name: 'France',
  functions: {
    validatePostalCode,
    convertGooglePlaceToAddress: convertGooglePlaceToAddress(
      streetFunctions.formatStreet
    ),
    ...streetFunctions,
    addressContainsState,
    preferredStreetFormat: () => STREET_FORMAT.SINGLE_FIELD,
    isAutocompleteAllowed: () => true,
    getRequiredFields: () => [
      'countryCode',
      'streetName',
      'city',
      'postalCode',
    ],
  },
}
