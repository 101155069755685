import pickBy from 'lodash.pickby'
import identity from 'lodash.identity'

export const ADDRESS_VERIFIED = 'VERIFIED'
export const ADDRESS_UNVERIFIED = 'UNVERIFIED'
export const ADDRESS_INCOMPLETE = 'INCOMPLETE'

export const ADDRESS_FIELD_VERIFIED = 'VERIFIED'
export const ADDRESS_FIELD_UNVERIFIED = 'UNVERIFIED'
export const ADDRESS_FIELD_INVALID = 'INVALID'

export const REASON_MUST_NOT_BE_EMPTY = 'REASON_MUST_NOT_BE_EMPTY'
export const REASON_INVALID_FORMAT = 'REASON_INVALID_FORMAT'

export const STREET_FORMAT = {
  SINGLE_FIELD: 'SINGLE_FIELD',
  SEPARATE_HOUSE_NUMBER: 'SEPARATE_HOUSE_NUMBER',
}

const defaultValues = {
  city: '',
  state: '',
  streetAndHouseNumber: '',
  streetName: '',
  houseNumber: '',
  postalCode: '',
}

export const createAddress = (countryCode = '', initialAddress = {}) => ({
  ...defaultValues,
  ...pickBy(initialAddress, identity),
  countryCode,
})
