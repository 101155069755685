import { DUMMY } from './dummy'
export { STREET_FORMAT } from '../address'

export const SUPPORTED_COUNTRIES = {}

function registerCountry({ countryCode, name, functions }) {
  SUPPORTED_COUNTRIES[countryCode] = {
    countryCode,
    name,
    functions,
  }
}

export function getCountryFunctions(countryCode) {
  if (!countryCode || !SUPPORTED_COUNTRIES[countryCode]) {
    return DUMMY.functions
  }
  return SUPPORTED_COUNTRIES[countryCode].functions
}

/* eslint-disable import/first */
import { NL } from './nl'
registerCountry(NL)

import { US } from './us'
registerCountry(US)

import { BE } from './be'
registerCountry(BE)

import { GB } from './gb'
registerCountry(GB)

import { IT } from './it'
registerCountry(IT)

import { DE } from './de'
registerCountry(DE)

import { IE } from './ie'
registerCountry(IE)

import { FR } from './fr'
registerCountry(FR)

import { ES } from './es'
registerCountry(ES)
