// PLEASE READ BEFORE MODIFYING
// Major and minor version number are maintained manually.
// Whenever there's a version in behaviour or contract we need to increment
// in package.json.
// Patch version doesn't have to be modified, it gets generated each time
// by the build machine.

import React from 'react'

import mountAutocompleteToDom from '../../model/mountAutocompleteToDom'
import { SUPPORTED_COUNTRIES } from '../../model/countries'
import { actions } from '../../state/addressForm'

let updateAddressAutocompleteCountry = () => {}
let updateAddressAutocompleteState = () => {}
let getAddressAutocompleteState = () => {}
const supportedCountryCodes = Object.keys(SUPPORTED_COUNTRIES)

class AddressAutocomplete extends React.Component {
  rootRef = React.createRef()

  componentDidMount() {
    this.autocompleteInstance = mountAutocompleteToDom(this.rootRef.current, {
      errorMessageMap: this.props.errorMessageMap,
      initialAddress: this.props.initialAddress,
    })

    if (typeof this.props.onFormBound === 'function') {
      this.props.onFormBound(this.autocompleteInstance)
    }

    this.autocompleteInstance.subscribe(this.validationStatusChanged)

    updateAddressAutocompleteCountry = (countryCode) => {
      this.autocompleteInstance.store.dispatch(
        actions.changeCountryCode(countryCode)
      )
    }

    updateAddressAutocompleteState = (state) => {
      const {
        addressForm: {
          address: { countryCode },
        },
      } = this.autocompleteInstance.store.getState()
      if (supportedCountryCodes.includes(countryCode)) {
        this.autocompleteInstance.store.dispatch(actions.changeState(state))
      }
    }

    getAddressAutocompleteState = () => {
      const {
        addressForm: {
          address: { state },
        },
      } = this.autocompleteInstance.store.getState()
      return state
    }
  }

  componentWillUnmount() {
    this.autocompleteInstance.unmount()
  }

  validationStatusChanged = (validationStatus) => {
    if (typeof this.props.onValidationStatusChanged === 'function') {
      this.props.onValidationStatusChanged(validationStatus)
    }
  }

  render() {
    return <div ref={this.rootRef}>{this.props.children}</div>
  }
}

class ErrorVisual extends React.Component {
  lastRender = null

  render() {
    const {
      fieldKeys,
      FormatError = (props) => props.children,
      FormatPlaceholder = (props) => props.children,
      placeHolder,
    } = this.props

    let fieldNames = []
    if (fieldKeys) {
      if (Array.isArray(fieldKeys)) {
        fieldNames = fieldKeys
      } else {
        fieldNames = [fieldKeys]
      }
    }

    // We need to trick react into thinking these errors' DOMs are never changing,
    // otherwise it overwrites the error messages added by direct DOM manipulation with empty strings.
    // These are all the other possibilities we've considered:
    //
    // * shouldComponentUpdate with _.isEqual - doesn't work because the props involve functions
    // and isEqual doesn't support them. It always assumes the content changed if it encounters a function.
    //
    // * shouldComponentUpdate with custom logic - works now but according to React documentation,
    // react can (and probably will) start using the result of that function just as a hint and decide to
    // update anyway at any time.
    //
    // * PureComponent - doesn't work either. Not sure why but I'm suspecting it has to do with functions in props too.
    //
    // We could still improve this by conditioning the suppression with custom comparison logic.
    if (!this.lastRender) {
      this.lastRender = (
        <div data-error-for-fields={fieldNames.join(' ')}>
          <FormatError>
            <span data-error-role="content" />
          </FormatError>
          {placeHolder ? (
            <FormatPlaceholder>
              <span data-error-role="placeHolder">{placeHolder}</span>
            </FormatPlaceholder>
          ) : null}
        </div>
      )
    }

    return this.lastRender
  }
}

export {
  AddressAutocomplete,
  ErrorVisual,
  updateAddressAutocompleteCountry,
  updateAddressAutocompleteState,
  getAddressAutocompleteState,
  supportedCountryCodes,
}
