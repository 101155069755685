import { addEventListener, removeEventListener } from './bindEvents'

function inputToStateBinding(input, onChange, valueAccessor) {
  // using native input value setter enables AddressAutocomplete users to use their own onChange function
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value'
  ).set

  let currentValue = input.value

  return {
    handleInput: (event) => {
      // notify listeners about the new requested state and hold off the actual change until a state change comes.
      if (event.target.value !== currentValue) {
        onChange(event.target.value)
        nativeInputValueSetter.call(input, currentValue)
      }
    },

    handleStateUpdate: (viewState) => {
      // If the value was changed in the viewState and it's different from what we think about the viewState, we update
      const updatedValue = valueAccessor(viewState)
      if (updatedValue !== currentValue) {
        currentValue = updatedValue
        nativeInputValueSetter.call(input, currentValue)
        input.dispatchEvent(new Event('input', { bubbles: true }))
      }
    },
  }
}

export default function bindInputToState(
  context,
  input,
  onChange,
  valueAccessor
) {
  const binding = inputToStateBinding(input, onChange, valueAccessor)
  const { subscribe } = context

  function handleInput(event) {
    binding.handleInput(event)
  }
  addEventListener(input, 'input', handleInput)

  const unsubscribe = subscribe((viewState) =>
    binding.handleStateUpdate(viewState)
  )

  binding.unbind = () => {
    unsubscribe()
    removeEventListener(input, 'input', handleInput)
  }

  return binding
}
