import {
  convertGooglePlaceToAddress,
  preferredStreetFormat,
  getRequiredFieldsWithState as getRequiredFields,
} from './eu'
import { streetFunctions } from '../rightSideHouseNumber'

/**
 * Validate postal code
 * Five digits, first two indicate the province. Third digit: large town, main delivery rounds. Last 2 digits: delivery area, secondary delivery route or link to rural areas.
 *
 * @link https://rgxdb.com/r/3EZMSVWM
 * @param {string} value
 */
function validatePostalCode(value) {
  return value.trim().match(/^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/) !== null
}

export const ES = {
  countryCode: 'ES',
  name: 'Spain',
  functions: {
    validatePostalCode,
    convertGooglePlaceToAddress: convertGooglePlaceToAddress(
      streetFunctions.formatStreet
    ),
    ...streetFunctions,
    addressContainsState: () => true,
    preferredStreetFormat,
    isAutocompleteAllowed: () => true,
    getRequiredFields,
  },
}
