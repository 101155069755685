import React from 'react'
import { Accordion, Button, Card } from 'react-bootstrap'

import { SUPPORTED_COUNTRIES } from '../../model/countries'

const FAQ_QUESTIONS_AND_ANSWERS = [
  {
    question:
      'When I select an option from autocomplete I get an error saying "ZIP MUST BE IN VALID FORMAT", why?',
    answer:
      "When you don't select address that points to one specific place, i.e. if you don' specify house number, there can be multiple valid ZIP codes for the address. In case like this we autocomplete only part of the ZIP code that is common for all valid ZIP codes of the address, the customer must then fill in the rest of the zip code, or provide more specific address (house number)",
  },
  {
    question:
      'I can only see suggestions for the street field. Is there an autocomplete for other fields?',
    answer:
      'The autocomplete is available only for street field, however once the user selects one of the suggestions, other fields will get populated.',
  },
  {
    question:
      'I am not getting autocomplete suggestions for some countries, why?',
    answer: `Not all countries are supported, currently supported countries are: ${Object.keys(
      SUPPORTED_COUNTRIES
    )
      .sort()
      .join(', ')
      .toUpperCase()}`,
  },
]

export default function Faq() {
  const faqRows = []

  let key = 0
  for (let faqRow of FAQ_QUESTIONS_AND_ANSWERS) {
    faqRows.push(
      <Card key={key}>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="text" eventKey={key}>
            {faqRow.question}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={key}>
          <Card.Body>{faqRow.answer}</Card.Body>
        </Accordion.Collapse>
      </Card>
    )
    key++
  }

  return <Accordion className="mb-3">{faqRows}</Accordion>
}
