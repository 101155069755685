import React, { useState } from 'react'
import {
  AddressAutocomplete,
  updateAddressAutocompleteCountry,
  updateAddressAutocompleteState,
  getAddressAutocompleteState,
} from '../../../AddressAutocomplete'
import { Select, TextField } from '@cimpress/react-components'
import countryList from './countryCodes.json'
import stateList from './state.json'

let validateForm
const onFormBound = (inst) => (validateForm = inst.validateForm)

async function onSubmit() {
  console.log(await validateForm())
}

export default function USForm() {
  const [country, setCountry] = useState({ label: '', value: '' })
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [stateSelect, setStateSelect] = useState({ label: '', value: '' })
  const [postalCode, setPostalCode] = useState('')

  return (
    <div className="container demoAddressForm">
      <AddressAutocomplete onFormBound={onFormBound}>
        <div className="row" key="row1">
          <div className="col-md-3" key="col1">
            <label className="required">Country</label>
          </div>

          <div
            className="col-md-7 mb-3"
            key="col2"
            style={{ marginTop: '-40px' }}
          >
            <Select
              id="country-selector"
              options={countryList}
              value={country}
              onChange={(selectedCountry) => {
                setCountry(selectedCountry)
                updateAddressAutocompleteCountry(selectedCountry.value)
              }}
            />
          </div>
        </div>

        <div className="row" key="row2">
          <div className="col-md-3" key="col1">
            <label className="required">Address</label>
          </div>

          <div className="col-md-7 mb-3" key="col2">
            <input
              data-address-role="streetNameAndHouseNumber"
              className="form-control"
              key="input"
              value={street}
              onChange={(e) => {
                setStreet(e.target.value)
                const autocompleteState = stateList.find(
                  (state) => state.label === getAddressAutocompleteState()
                )
                if (autocompleteState) setStateSelect(autocompleteState)
              }}
            />
          </div>
        </div>

        <div className="row" key="row4">
          <div className="col-md-3" key="col1">
            <label className="required">City</label>
          </div>

          <div className="col-md-7 mb-3" key="col2" data-address-role="city">
            <TextField
              placeholder=""
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
        </div>

        <div className="row" key="state" data-address-role="state">
          <div className="col-md-3" key="col1">
            <label className="required">State</label>
          </div>

          {country && country.value === 'US' ? (
            <div
              className="col-md-7 mb-3"
              key="col2"
              style={{ marginTop: '-40px' }}
            >
              <Select
                id="state-selector"
                options={stateList}
                value={stateSelect}
                onChange={(selectedState) => {
                  setStateSelect(selectedState)
                  updateAddressAutocompleteState(selectedState.label)
                }}
              />
            </div>
          ) : (
            <div className="col-md-7 mb-3" key="col2">
              <TextField
                placeholder=""
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className="row" key="row5">
          <div className="col-md-3" key="col1">
            <label className="required">ZIP</label>
          </div>

          <div
            className="col-md-7 mb-3"
            key="col2"
            data-address-role="postalCode"
          >
            <TextField
              placeholder=""
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </div>
        </div>
      </AddressAutocomplete>

      <hr className="mb-4" />

      <div className="row">
        <button className="btn btn-primary btn-lg btn-block" onClick={onSubmit}>
          Submit
        </button>
      </div>
    </div>
  )
}
